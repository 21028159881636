.menu {
    @include media-breakpoint-down(sm) {
        &:checked {
            + .nav-btn .nav-btn__inner {
                background: transparent;
                &::before {
                    transform: rotate(45deg) translateY(0) translateX(.2rem);
                }
                &::after {
                    transform: rotate(-45deg) translateY(0) translateX(0);
                }
            }
            ~ .nav {
                padding-bottom: 15vh;
                height: 100vh;

                .nav__block {
                    li {
                        transform: translate3d(0,0,0);
                        opacity: 1;
                    }
                }

                .nav__block--right {
                    li {
                        $delay: 275ms;
                        @for $i from 1 through 3 {
                            &:nth-of-type(#{$i}) {  transition-delay: $delay;  }
                            $delay: $delay + 25ms;
                        }
                    }
                }
                .nav__block--left {
                    li {
                        $delay: 200ms;
                        @for $i from 1 through 3 {
                            &:nth-of-type(#{$i}) {  transition-delay: $delay;  }
                            $delay: $delay + 25ms;
                        }
                    }
                }
            }
        }
    }
}

.nav-btn {
    position: fixed;
    top: 1.2rem;
    right: 2rem;
    z-index: 6;
    margin: 0;
    width: 3.4rem;
    height: 2.8rem;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
        display: none;
    }
}


.nav-btn__inner {
    position: relative;
    z-index: 1;

    &::before,
    &::after,
    & {
        content: '';
        width: 100%;
        height: .2rem;
        display: block;
        background: #FFF;
        transform-origin: center;

        transition: background .3s ease, transform .3s ease;
    }

    &::before {
        transform: rotate(0) translateY(-.8rem) translateX(0);
    }
    &::after {
        transform: rotate(0) translateY(.6rem) translateX(0);
    }
}
