.info-box {
    padding: 3rem 0;

    @include media-breakpoint-up('sm') {
        padding: 7rem 0;
    }
}

.info-box--header {
    margin-top: 8rem;

    @include media-breakpoint-up('sm') {
        margin-top: 11rem;
    }
    .info-box__head {
        display: none;
    }
}

.info-box--header {
    padding: 0;
    ._headline {
        display: none;
    }
}

.info-box__head {
    margin-bottom: 2rem;
    text-align: center;
}

.oo-infos {
    text-align: center;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.oo-infos__block {
    margin: 2rem 0;
    line-height: 1.4;
    font-size: 1.6rem;

    @include media-breakpoint-up(sm) {
        margin: 0 1rem;
    }
    @include media-breakpoint-up(md) {
        margin: 0 4rem;
    }
    @include media-breakpoint-up(lg) {
        margin: 0 6rem;
    }
}

.oo-infos__icon {
    height: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line {
    display: block;
    white-space: nowrap;
}

.intro-text {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #ee2b3b;
    color: #FFF;
    font-size: 2rem;
    line-height: 1.6;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        margin-top: 1rem;
        font-size: 2.4rem;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 101%;
            height: 102%;
            background-image: url('../img/logo-optik-oepen_black.svg');
            background-repeat: no-repeat;
            background-size: 50% auto;
            background-position: bottom right;
            opacity: .2;
        }
    }
    @include media-breakpoint-up(xl) {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}
