.gallery {
    @include media-breakpoint-down(md) {
        padding: 0 !important;
    }
}

.gallery__shadow {
    @include media-breakpoint-down(md) {
        position: relative;
        width: 100%;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 1.3rem;
            left: auto;
            right: .3rem;
            bottom: 1.3rem;
            z-index: 5;
            width: 3rem;
            display: none;
            background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 90%);
        }
    }
}

.gallery__img-block {
    @include media-breakpoint-down(md) {
        width: 100%;
        height: 55vh;
        padding: 0 !important;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    img {
        width: 100%;
        margin-bottom: .6rem;

        @include media-breakpoint-down(md) {
            margin: 0 .3rem;
            height: 55vh;
            width: auto;
            flex: 0 0 auto;
        }
    }
}
