.oh-info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    padding: 1.2rem .8em;
    background-color: $main-color;
    color: $white;
    font-weight: bold;
    line-height: 1.2;

    &__inner {
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
    }
}
