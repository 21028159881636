.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #868e96;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
@-ms-viewport {
  width: device-width;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

a,
area,
button,
[role=button],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

/* raleway-regular - latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/raleway-v28-latin-regular.woff2") format("woff2"), url("../fonts/raleway-v28-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-800italic - latin */
@font-face {
  font-family: "Raleway";
  font-style: italic;
  font-weight: 800;
  src: local(""), url("../fonts/raleway-v28-latin-800italic.woff2") format("woff2"), url("../fonts/raleway-v28-latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.panorama {
  margin-top: 4rem;
}

.caption {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: 1;
  padding: 0.4rem 1rem;
  background: #FFF;
  border-radius: 0.4rem;
}

.co-headline {
  margin: 3.5rem 0 2rem;
}
@media (min-width: 768px) {
  .co-headline {
    margin: 5rem 0 3rem;
    text-align: center;
  }
}

.impressum {
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .impressum {
    margin-top: 8rem;
  }
}

@media (max-width: 991.98px) {
  .mp {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.footer {
  background: #f5f7fa;
  padding: 4rem 0;
}
@media (min-width: 768px) {
  .footer {
    text-align: center;
    padding: 6rem 0 4rem;
  }
}

.footer__brands {
  margin-top: 2rem;
  column-count: 2;
  column-gap: 0.6rem;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
@media (min-width: 768px) {
  .footer__brands {
    column-count: 3;
  }
}
@media (min-width: 992px) {
  .footer__brands {
    column-count: 4;
  }
}
.footer__brands .item .item__inner {
  padding: 0 2rem;
  margin: 0 0 0.6rem;
}
@media (min-width: 768px) {
  .footer__brands .item .item__inner {
    padding: 0 5rem;
  }
}
.footer__brands .item img {
  width: 100%;
  margin: 2rem 0;
}
@media (min-width: 768px) {
  .footer__brands .item img {
    margin: 3rem 0;
  }
}

.footer__links {
  margin: 3rem 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  list-style-type: none;
}
@media (min-width: 768px) {
  .footer__links {
    margin: 5rem 0 0;
  }
}
.footer__links li {
  padding: 0 1rem;
}

.contribute {
  margin-top: 1.5rem;
  font-size: 1.2rem;
}
.contribute a {
  color: #868e96;
  font-weight: normal;
}

.text-ce {
  text-align: center;
}

.jc-ce {
  justify-content: center;
}

@media (max-width: 767.98px) {
  .menu:checked + .nav-btn .nav-btn__inner {
    background: transparent;
  }
  .menu:checked + .nav-btn .nav-btn__inner::before {
    transform: rotate(45deg) translateY(0) translateX(0.2rem);
  }
  .menu:checked + .nav-btn .nav-btn__inner::after {
    transform: rotate(-45deg) translateY(0) translateX(0);
  }
  .menu:checked ~ .nav {
    padding-bottom: 15vh;
    height: 100vh;
  }
  .menu:checked ~ .nav .nav__block li {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .menu:checked ~ .nav .nav__block--right li:nth-of-type(1) {
    transition-delay: 275ms;
  }
  .menu:checked ~ .nav .nav__block--right li:nth-of-type(2) {
    transition-delay: 300ms;
  }
  .menu:checked ~ .nav .nav__block--right li:nth-of-type(3) {
    transition-delay: 325ms;
  }
  .menu:checked ~ .nav .nav__block--left li:nth-of-type(1) {
    transition-delay: 200ms;
  }
  .menu:checked ~ .nav .nav__block--left li:nth-of-type(2) {
    transition-delay: 225ms;
  }
  .menu:checked ~ .nav .nav__block--left li:nth-of-type(3) {
    transition-delay: 250ms;
  }
}

.nav-btn {
  position: fixed;
  top: 1.2rem;
  right: 2rem;
  z-index: 6;
  margin: 0;
  width: 3.4rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .nav-btn {
    display: none;
  }
}

.nav-btn__inner {
  position: relative;
  z-index: 1;
}
.nav-btn__inner::before, .nav-btn__inner::after, .nav-btn__inner {
  content: "";
  width: 100%;
  height: 0.2rem;
  display: block;
  background: #FFF;
  transform-origin: center;
  transition: background 0.3s ease, transform 0.3s ease;
}
.nav-btn__inner::before {
  transform: rotate(0) translateY(-0.8rem) translateX(0);
}
.nav-btn__inner::after {
  transform: rotate(0) translateY(0.6rem) translateX(0);
}

.service-list {
  margin: 1rem 0 0;
  padding: 0;
  list-style-type: none;
}
@media (min-width: 768px) {
  .service-list {
    font-size: 1.8rem;
  }
}
.service-list li {
  margin: 0.8rem 0;
  padding-left: 3rem;
  background: url("../img/chevron-right.svg") 0 0.5rem no-repeat/1.6rem 1.6rem;
}
@media (min-width: 768px) {
  .service-list li {
    margin: 0.5rem 0;
    background-position: 0 1rem;
  }
}

::selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

.masonry img {
  width: 100%;
}

@media (max-width: 991.98px) {
  .gallery {
    padding: 0 !important;
  }
}

@media (max-width: 991.98px) {
  .gallery__shadow {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .gallery__shadow::before {
    content: "";
    position: absolute;
    top: 1.3rem;
    left: auto;
    right: 0.3rem;
    bottom: 1.3rem;
    z-index: 5;
    width: 3rem;
    display: none;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 90%);
  }
}

@media (max-width: 991.98px) {
  .gallery__img-block {
    width: 100%;
    height: 55vh;
    padding: 0 !important;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.gallery__img-block img {
  width: 100%;
  margin-bottom: 0.6rem;
}
@media (max-width: 991.98px) {
  .gallery__img-block img {
    margin: 0 0.3rem;
    height: 55vh;
    width: auto;
    flex: 0 0 auto;
  }
}

.the-grid {
  padding: 0.3rem;
}

@media (min-width: 768px) {
  .the-grid--intro {
    margin-top: 4rem;
  }
}

.item {
  padding: 0.3rem;
}

.item--stretch {
  display: flex;
}

@media (min-width: 768px) {
  .item--intro-text {
    order: 2;
  }
}

@media (min-width: 768px) {
  .item--intro-text-2 {
    order: 3;
  }
}

@media (min-width: 768px) {
  .item--intro-img {
    order: 1;
  }
}

@media (min-width: 768px) {
  .item--intro-img-2 {
    order: 4;
  }
}

.item__inner {
  width: 100%;
  background: #f5f7fa;
}

.item__inner--xs {
  height: 20rem;
}

.item__inner--center {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .item__inner--center {
    padding: 4rem;
  }
}
@media (max-width: 767.98px) {
  .item__inner--center {
    height: auto;
  }
}
.item__inner--center h1, .item__inner--center h2, .item__inner--center h3, .item__inner--center p, .item__inner--center ul {
  width: 100%;
}

.item__inner--red {
  background-color: #ee2b3b;
}

@media (min-width: 768px) {
  .item--services {
    padding: 0 2rem;
    margin-top: -6rem;
  }
}
@media (min-width: 992px) {
  .item--services {
    padding: 0 4rem;
  }
}
@media (min-width: 768px) {
  .item--services .item__inner {
    background: #FFF;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
  }
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.row--teaser {
  justify-content: center;
}
@media (min-width: 1200px) {
  .row--teaser {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}

.teaser {
  margin: 3rem 0;
  max-width: 40rem;
}
@media (max-width: 767.98px) {
  .teaser {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .teaser {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .teaser {
    margin: 4rem 0;
  }
}
@media (min-width: 1200px) {
  .teaser {
    margin: 5rem 0;
    padding: 0 3rem;
  }
}
@media (min-width: 768px) {
  .teaser p {
    padding-bottom: 1rem;
  }
}
.teaser a {
  margin-top: auto;
}

.teaser__img {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .teaser__img {
    margin-bottom: 0;
    height: 13rem;
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .row--single {
    margin-bottom: 4rem;
  }
}

.img-store {
  width: 12rem;
}

.img-store--xs {
  width: 8rem;
}

.img-clock--xs {
  width: 6rem;
}

.img-contact--xs {
  width: 7rem;
}

.img-service {
  width: 11rem;
}

.img-sunglasses {
  width: 20rem;
}

.img-sunglasses--xs {
  width: 16rem;
}

.img-glasses {
  width: 19rem;
}

.img-kids {
  width: 17rem;
}

/* Kontaktlinsen */
.img-lenses {
  width: 28rem;
  margin: 2rem 0;
}

/* Leistungen */
.img-tool {
  width: 28rem;
  margin: 2rem 0;
}

.img-checklist {
  width: 16rem;
  margin: 2rem 0;
}

.img-logo {
  width: 22rem;
  margin: 2rem 0;
}

.info-box {
  padding: 3rem 0;
}
@media (min-width: 576px) {
  .info-box {
    padding: 7rem 0;
  }
}

.info-box--header {
  margin-top: 8rem;
}
@media (min-width: 576px) {
  .info-box--header {
    margin-top: 11rem;
  }
}
.info-box--header .info-box__head {
  display: none;
}

.info-box--header {
  padding: 0;
}
.info-box--header ._headline {
  display: none;
}

.info-box__head {
  margin-bottom: 2rem;
  text-align: center;
}

.oo-infos {
  text-align: center;
}
@media (min-width: 576px) {
  .oo-infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.oo-infos__block {
  margin: 2rem 0;
  line-height: 1.4;
  font-size: 1.6rem;
}
@media (min-width: 576px) {
  .oo-infos__block {
    margin: 0 1rem;
  }
}
@media (min-width: 768px) {
  .oo-infos__block {
    margin: 0 4rem;
  }
}
@media (min-width: 992px) {
  .oo-infos__block {
    margin: 0 6rem;
  }
}

.oo-infos__icon {
  height: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  display: block;
  white-space: nowrap;
}

.intro-text {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #ee2b3b;
  color: #FFF;
  font-size: 2rem;
  line-height: 1.6;
  overflow: hidden;
}
@media (min-width: 992px) {
  .intro-text {
    margin-top: 1rem;
    font-size: 2.4rem;
  }
  .intro-text::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 102%;
    background-image: url("../img/logo-optik-oepen_black.svg");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: bottom right;
    opacity: 0.2;
  }
}
@media (min-width: 1200px) {
  .intro-text {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.oh-info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  padding: 1.2rem 0.8em;
  background-color: #ee2b3b;
  color: #fff;
  font-weight: bold;
  line-height: 1.2;
}
.oh-info__inner {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}