.the-grid {
    padding: .3rem;
}

.the-grid--intro {
    @include media-breakpoint-up(md) {
        margin-top: 4rem;
    }
}

.item {
    padding: .3rem;
}

.item--stretch {
    display: flex;
}

.item--intro-text {
    @include media-breakpoint-up(md) {
        order: 2;
    }
}
.item--intro-text-2 {
    @include media-breakpoint-up(md) {
        order: 3;
    }
}
.item--intro-img {
    @include media-breakpoint-up(md) {
        order: 1;
    }
}
.item--intro-img-2 {
    @include media-breakpoint-up(md) {
        order: 4;
    }
}

.item__inner {
    width: 100%;
    background: #f5f7fa;
}

.item__inner--xs {
    height: 20rem
}

.item__inner--center {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up('md') {
        padding: 4rem;
    }
    @include media-breakpoint-down('sm') {
        height: auto;
    }

    h1, h2, h3, p, ul {
        width: 100%;
    }
}

.item__inner--red {
    background-color: #ee2b3b;
}

.item--services {
    @include media-breakpoint-up('md') {
        padding: 0 2rem;
        margin-top: -6rem;
    }
    @include media-breakpoint-up('lg') {
        padding: 0 4rem;
    }
    .item__inner {
        @include media-breakpoint-up('md') {
            background: #FFF;
            box-shadow: 0 0 35px 0 rgba(0, 0, 0, .05);
        }
    }
}

.img-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.row--teaser {
    justify-content: center;
    @include media-breakpoint-up(xl) {
        margin-left: -3rem;
        margin-right: -3rem;
    }
}

.teaser {
    margin: 3rem 0;
    max-width: 40rem;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
    }
    @include media-breakpoint-up(lg) {
        margin: 4rem 0;
    }
    @include media-breakpoint-up(xl) {
        margin: 5rem 0;
        padding: 0 3rem;
    }

    p {
        @include media-breakpoint-up(md) {
            padding-bottom: 1rem;
        }
    }

    a {
        margin-top: auto;
    }
}

.teaser__img {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        height: 13rem;
        justify-content: flex-start;
    }
}

.row--single {
    @include media-breakpoint-up(md) {
        margin-bottom: 4rem;
    }
}

.img-store {
    width: 12rem;
}
.img-store--xs {
    width: 8rem;
}
.img-clock--xs {
    width: 6rem;
}
.img-contact--xs {
    width: 7rem;
}
.img-service {
    width: 11rem;
}
.img-sunglasses {
    width: 20rem;
}
.img-sunglasses--xs {
    width: 16rem;
}
.img-glasses {
    width: 19rem;
}
.img-kids {
    width: 17rem;
}

/* Kontaktlinsen */
.img-lenses {
    width: 28rem;
    margin: 2rem 0;
}

/* Leistungen */
.img-tool {
    width: 28rem;
    margin: 2rem 0;
}
.img-checklist {
    width: 16rem;
    margin: 2rem 0;
}
.img-logo {
    width: 22rem;
    margin: 2rem 0;
}
