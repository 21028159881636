.service-list {
    margin: 1rem 0 0;
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up('md') {
        font-size: 1.8rem;
    }

    li {
        margin: .8rem 0;
        padding-left: 3rem;
        background: url("../img/chevron-right.svg") 0 .5rem no-repeat / 1.6rem 1.6rem;
        @include media-breakpoint-up('md') {
            margin: .5rem 0;
            background-position: 0 1rem;
        }
    }
}
