.panorama {
    margin-top: 4rem;
}

.caption {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 1;
    padding: .4rem 1rem;
    background: #FFF;
    border-radius: .4rem;
}

.co-headline {
    margin: 3.5rem 0 2rem;

    @include media-breakpoint-up(md) {
        margin: 5rem 0 3rem;
        text-align: center;
    }
}

.impressum {
    margin-top: 3rem;
    @include media-breakpoint-up(md) {
        margin-top: 8rem;
    }
}

.mp {
    @include media-breakpoint-down(md) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}
