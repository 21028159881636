.footer {
    background: #f5f7fa;
    padding: 4rem 0;
    @include media-breakpoint-up('md') {
        text-align: center;
        padding: 6rem 0 4rem;
    }
}

.footer__brands {
    margin-top: 2rem;

    @include media-breakpoint-up('xs') {
        column-count: 2;
        column-gap: .6rem;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }
    @include media-breakpoint-up('md') {
        column-count: 3;
    }
    @include media-breakpoint-up('lg') {
        column-count: 4;
    }

    .item {
        .item__inner {
            padding: 0 2rem;
            margin: 0 0 .6rem;

            @include media-breakpoint-up('md') {
                padding: 0 5rem;
            }
        }

        img {
            width: 100%;
            margin: 2rem 0;
            @include media-breakpoint-up('md') {
                margin: 3rem 0;
            }
        }
    }
}

.footer__links {
    margin: 3rem 0 0;
    padding: 0;
    display: flex;
    justify-content: center;
    list-style-type: none;

    @include media-breakpoint-up('md') {
        margin: 5rem 0 0;
    }

    li {
        padding: 0 1rem;
    }
}

.contribute {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    a {
        color: $gray-600;
        font-weight: normal;
    }
}
