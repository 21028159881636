::selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-moz-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection{
  background: #333;
  color: #fff;
  text-shadow: none;
}



.masonry {
  img {
    width: 100%;
  }
}
